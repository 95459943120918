




































import { teamsStore, uiStore } from '@/store'
import { format } from 'quasar'
import { Component, Vue } from 'vue-property-decorator'

const { capitalize } = format

@Component({
  components: {
    MessageCounter: () => import('@/components/Meetings/components/MessageCounter.vue'),
  },
})
export default class HeaderMenu extends Vue {
  get items () {
    const items = []

    const contacts = {
      key: 'contacts',
      icon: () => import('@tada/icons/dist/IconContacts.vue'),
      label: 'contact',
      tutorialId: 1,
    }
    const fileBrowser = {
      key: 'file-browser',
      icon: () => import('@tada/icons/dist/IconFile.vue'),
      label: 'file',
      tutorialId: 6,
    }

    if (uiStore.getters.showTasksRelated) {
      const tasks = {
        key: 'tasks',
        icon: () => import('@tada/icons/dist/IconCheckSquare.vue'),
        label: 'task',
        badge: true,
        unread: this.unreadTaskCounter,
        unreadNotice: this.unreadTaskNotice,
      }
      items.push(tasks)
    }

    const meetings = {
      key: 'meetings',
      icon: () => import('@tada/icons/dist/IconCalendar.vue'),
      label: 'meetings',
      badge: true,
      unread: this.unreadMeetingCounter,
      unreadNotice: this.unreadMeetingNotice,
    }

    items.push(meetings, fileBrowser, contacts)

    return items
  }

  get activeItem () {
    return uiStore.getters.currentRightBarInstance
  }

  get currentTeam () {
    return teamsStore.getters.currentTeam
  }

  get unreadTaskCounter () {
    return this.currentTeam.unread.task.chats
  }

  get unreadTaskNotice () {
    return this.currentTeam.unread.task.noticeMessages
  }

  get unreadMeetingCounter () {
    return this.currentTeam.unread.meeting.chats
  }

  get unreadMeetingNotice () {
    return this.currentTeam.unread.meeting.noticeMessages
  }

  private isActive (key: string) {
    return this.activeItem.indexOf(key) >= 0
  }

  private getTitle (id: string) {
    return capitalize(this.$t(`glossary.${id}_plural`).toString())
  }

  private handleItemClick (key: string) {
    const isAlreadyActive = this.isActive(key)

    const payload = { instance: key, payload: null, hide: isAlreadyActive }
    uiStore.actions.switchRightBarInstance(payload)
  }
}
